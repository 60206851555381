import i18n from '@/i18n';
import Vue from 'vue';
import Vuetify, { VIcon } from 'vuetify/lib';

import iconFrenchSpoken from '@/assets/icons/iconFrenchSpoken.vue';
import iconFleurDeLys from '@/assets/icons/iconFleurDeLys.vue';

Vue.use(Vuetify, {
  components: { VIcon },
});

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params) as string, // will look in i18n files for $vuetify object for translations - https://vuetifyjs.com/en/customization/internationalization#create-translation
  },
  icons: {
    values: {
      frenchSpoken: {
        component: iconFrenchSpoken,
      },
      fleurDeLys: {
        component: iconFleurDeLys,
      },
    },
  },
});
