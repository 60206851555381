import { RouteConfig } from 'vue-router';
import Form from '@/views/layout/Form.vue';
import i18n from '@/i18n';

const routes: Array<RouteConfig> = [
  {
    path: 'user-agreement',
    name: 'user-agreement',
    component: Form,
    redirect: { name: 'user-agreement-list' },
    meta: {
      session: true,
    },
    children: [
      {
        path: 'list',
        name: 'user-agreement-list',
        component: () => import('@/views/userAgreement/UserAgreementList.vue'),
        meta: {
          header: {
            label: () => i18n.tc('user-agreement.label', 2),
          },
        },
      },
      {
        path: 'create',
        name: 'user-agreement-create',
        component: () =>
          import('@/views/userAgreement/UserAgreementCreate.vue'),
        meta: {
          header: {
            route: { name: 'user-agreement-list' },
            label: () =>
              i18n.t('title.create', [i18n.tc('user-agreement.label')]),
          },
        },
      },
      {
        path: 'edit/:id',
        name: 'user-agreement-edit',
        component: () => import('@/views/userAgreement/UserAgreementEdit.vue'),
        meta: {
          header: {
            route: { name: 'user-agreement-list' },
            label: () =>
              i18n.t('title.edit', [i18n.tc('user-agreement.label')]),
          },
        },
      },
    ],
  },
];

export default routes;
