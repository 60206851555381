import Vue from 'vue';
import { LanguageObject } from '@/utils/LocaleUtils';
import SnackbarComponent from './SnackbarComponent.vue';
import vuetify from '@/plugins/vuetify';
import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';

interface SnackBarProp {
  text: string | TranslateResult | LanguageObject;
  type: 'success' | 'error' | 'warning' | 'info';
}

const queue: SnackBarProp[] = [];
let current: SnackBarProp | undefined;

const process = () => {
  const element = document.getElementById('toast');
  if (!current && element) {
    current = queue.shift(); // get the current message off the queue
    if (current) {
      element?.appendChild(render(current)?.$el); // render and add the component to the DOM
    }
  } else if (!element) {
    setTimeout(() => {
      process(); // keep trying until the element is found, not visible on page load immediately sometimes
    }, 500);
  }
};

const render = (data: SnackBarProp) => {
  const ToastComponent = Vue.extend(SnackbarComponent);
  return new ToastComponent({
    el: document.createElement('div'),
    vuetify, // need to import pluggins becuase this is a new instance outside of the app instance
    i18n, // need to import pluggins becuase this is a new instance outside of the app instance
    propsData: {
      value: data,
    },
    close: () => {
      current = undefined;
      process();
    },
  } as any);
};

export { SnackBarProp };

export default {
  push: (props: SnackBarProp) => {
    queue.push(props);
    process();
  },
};
