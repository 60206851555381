import { RouteConfig } from 'vue-router';
import Form from '@/views/layout/Form.vue';
import Tabs from '@/views/layout/Tabs.vue';
import i18n from '@/i18n';

const routes: Array<RouteConfig> = [
  {
    path: 'pointofservice',
    name: 'pointofservice',
    component: Form,
    redirect: { name: 'pointofservice-list' },
    meta: {
      session: true,
    },
    children: [
      {
        path: 'list',
        name: 'pointofservice-list',
        component: () =>
          import('@/views/pointofservice/PointOfServiceList.vue'),
        meta: {
          header: {
            label: () => i18n.tc('pointofservice.label', 2),
          },
        },
      },
      {
        path: 'create',
        name: 'pointofservice-create',
        component: () =>
          import('@/views/pointofservice/PointOfServiceCreate.vue'),
        meta: {
          header: {
            route: { name: 'pointofservice-list' },
            label: () =>
              i18n.t('title.create', [i18n.tc('pointofservice.label', 2)]),
          },
        },
      },
      {
        path: 'edit/:id(\\d+)',
        name: 'pointofservice-edit',
        component: Tabs,
        meta: {
          header: {
            route: { name: 'pointofservice-list' },
            label: () =>
              i18n.t('title.edit', [i18n.tc('pointofservice.label', 2)]),
          },
          tabs: [
            {
              route: { name: 'pointofservice-edit-form' },
              display: () => i18n.t('navigation.info'),
            },
            {
              route: { name: 'pointofservice-edit-description' },
              display: () => i18n.t('pointofservice.description'),
            },
            {
              route: { name: 'pointofservice-edit-service' },
              display: () => i18n.tc('service.label', 2),
            },
          ],
        },
        redirect: { name: 'pointofservice-edit-form' },
        children: [
          {
            path: '',
            name: 'pointofservice-edit-form',
            component: () =>
              import('@/views/pointofservice/PointOfServiceEdit.vue'),
          },
          {
            path: 'description',
            name: 'pointofservice-edit-description',
            component: () =>
              import(
                '@/views/pointofservice/PointOfServiceEditDescription.vue'
              ),
          },
          {
            path: 'service',
            name: 'pointofservice-edit-service',
            component: () =>
              import('@/views/pointofservice/PointOfServiceEditService.vue'),
          },
        ],
      },
    ],
  },
];

export default routes;
