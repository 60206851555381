import { RouteConfig } from 'vue-router';
import Form from '@/views/layout/Form.vue';
import i18n from '@/i18n';

const routes: Array<RouteConfig> = [
  {
    path: 'flsactivity',
    name: 'fls-activity',
    component: Form,
    redirect: { name: 'fls-activity-list' },
    meta: {
      session: true,
    },
    children: [
      {
        path: 'list',
        name: 'fls-activity-list',
        component: () => import('@/views/flsActivity/FlsActivityList.vue'),
        meta: {
          header: {
            label: () => i18n.tc('fls_activity.label', 2),
          },
        },
      },
      {
        path: 'create',
        name: 'fls-activity-create',
        component: () => import('@/views/flsActivity/FlsActivityCreate.vue'),
        meta: {
          header: {
            route: { name: 'fls-activity-list' },
            label: () =>
              i18n.t('title.create', [i18n.tc('fls_activity.label_title', 1)]),
          },
        },
      },
      {
        path: 'edit/:id',
        name: 'fls-activity-edit',
        component: () => import('@/views/flsActivity/FlsActivityEdit.vue'),
        meta: {
          header: {
            route: { name: 'fls-activity-list' },
            label: () =>
              i18n.t('title.edit', [i18n.tc('fls_activity.label_title', 1)]),
          },
        },
      },
      {
        path: 'view/:id',
        name: 'fls-activity-view',
        component: () => import('@/views/flsActivity/FlsActivityView.vue'),
        meta: {
          header: {
            route: { name: 'fls-activity-list' },
            label: () =>
              i18n.t('title.view', [i18n.tc('fls_activity.label_title', 1)]),
          },
        },
      },
    ],
  },
];

export default routes;
