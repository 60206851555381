import { clone } from 'lodash';

const clean = <T = any>(object: T): T | void | null => {
  const item = clone(object);

  for (const key in item) {
    if (item[key] instanceof Object) {
      // @ts-ignore
      item[key] = clean(item[key]);
    }

    if (item[key] === undefined) {
      delete item[key];
    } else if (typeof item[key] === 'string' && !item[key]) {
      // @ts-ignore
      item[key] = null; // send null instead of blank strings
    }
  }

  // check keys (after they have been cleaned) to see if undefined should be returned
  const keys = Object.keys(item);
  if (keys.length === 0) {
    return undefined;
    // @ts-ignore --- if every key has the value null, the object should be null
  } else if (keys.every((key) => item[key] === null)) {
    return null;
  }
  return item;
};

const error = (error: any) => {
  return error;
};

export default { clean, error };
