




















import UserAgreementUtils, { UserAgreement } from '@/utils/UserAgreementUtils';
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Boolean as () => boolean,
    },
    agreementKey: {
      type: String as () => string,
    },
  },

  computed: {
    loading(): boolean {
      return !!this.currentAgreement;
    },
    visible: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
  },

  data: (): {
    currentAgreement?: UserAgreement;
  } => ({
    currentAgreement: undefined,
  }),

  watch: {
    visible: {
      handler() {
        if (this.visible == true) {
          this.getAgreement();
        }
      },
    },
  },

  methods: {
    async getAgreement() {
      try {
        this.currentAgreement = await UserAgreementUtils.userAgreement.api.get(
          this.agreementKey
        );
      } catch (error) {
        console.error(error);
      }
    },

    async cancel() {
      this.currentAgreement = undefined;
    },
  },
});
