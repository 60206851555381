import i18n, { locales } from '@/i18n';
import CommonUtils from './CommonUtils';

interface LanguageObject {
  [key: string]: string | undefined;
}

const langobj = {
  parse: (val: LanguageObject | undefined, displayDefault = false) => {
    if (val) {
      let result = val[i18n.locale];

      // if string is not specified in current locale, loop through provided fallback array until one is found
      if (!result) {
        const locale = locales.find((locale) => i18n.locale === locale.value);
        if (locale) {
          locale.fallback?.some((fallback) => {
            if (val[fallback])
              result =
                (displayDefault ? '(' + fallback + ') ' : ' ') + val[fallback];
            return !!result;
          });
        }
      }

      return result?.trim();
    }
  },

  filter: (val: LanguageObject, search: string) => {
    return locales.some((locale) => {
      const value = val[locale.value];
      return value ? CommonUtils.clean(value)?.includes(search) : false;
    });
  },

  generate: (val?: any): LanguageObject => {
    return locales.reduce((result: LanguageObject, locale) => {
      if (typeof val === 'string') {
        result[locale.value] = val;
      } else if (typeof val === 'object') {
        // if an object, assume it is a LanguageObject
        result[locale.value] = val ? val[locale.value] : '';
      }
      return result;
    }, {});
  },

  label: (label: string, locale: string) => {
    return `${label} (${locale?.toLocaleUpperCase()})`;
  },
};

export default {
  langobj,
};
export { LanguageObject };
