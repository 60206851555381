import axios from 'axios';
import APIUtils from './APIUtils';
import LocaleUtils, { LanguageObject } from './LocaleUtils';

interface UserAgreement {
  id: number;
  key: string;
  name_admin: string;
  type: string;
  title: LanguageObject;
  text: LanguageObject;
  checkbox_label: LanguageObject;
  active: boolean;
  modified_at?: string;
  created_at: string;
}

const userAgreement = {
  generateDefault: (item?: Partial<UserAgreement>): Partial<UserAgreement> => {
    return {
      id: item?.id,
      key: item?.key,
      name_admin: item?.name_admin,
      type: item?.type,
      title: LocaleUtils.langobj.generate(item?.title),
      text: LocaleUtils.langobj.generate(item?.text),
      checkbox_label: LocaleUtils.langobj.generate(item?.checkbox_label),
      active: item?.active || false,
      modified_at: item?.modified_at,
      created_at: item?.created_at,
    };
  },

  api: {
    list: async () => {
      const resp = await axios.get<UserAgreement[]>('userAgreement/list.php');
      return resp.data;
    },
    listActiveTerm: async () => {
      const resp = await axios.get<UserAgreement[]>('userAgreement/list.php', {
        params: { active: true, type: 'term' },
      });
      return resp.data;
    },
    get: async (id: number | string) => {
      const resp = await axios.get<UserAgreement>('userAgreement/get.php', {
        params: { id: id },
      });
      return resp.data;
    },
    create: async (item: Partial<UserAgreement>) => {
      const resp = await axios.post<UserAgreement>('userAgreement/create.php', {
        ...APIUtils.clean(item),
      });
      return resp.data;
    },
    update: async (item: Partial<UserAgreement>) => {
      const resp = await axios.put<UserAgreement>('userAgreement/update.php', {
        ...APIUtils.clean(item),
      });
      return resp.data;
    },
    remove: async (id: number | string) => {
      const resp = await axios.delete<UserAgreement>(
        'userAgreement/remove.php',
        {
          params: { id: id },
        }
      );
      return resp.data;
    },
    isAcknowledged: (key: string) => {
      const local = localStorage.getItem('agreements');
      const agreements = local ? JSON.parse(local) : {};
      return agreements ? agreements[key] : false;
    },
    setAcknowledged: (key: string) => {
      const local = localStorage.getItem('agreements');
      const agreements = local ? JSON.parse(local) : {};
      agreements[key] = true;
      localStorage.setItem('agreements', JSON.stringify(agreements));
    },
  },
};

export default { userAgreement };
export { UserAgreement };
