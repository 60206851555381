












import Vue from 'vue';
import UserAgreementTermModal from '@/components/userAgreement/UserAgreementTermModal.vue';

export default Vue.extend({
  components: {
    UserAgreementTermModal,
  },
  data: () => ({
    //
  }),
});
