












import Vue from 'vue';

export default Vue.extend({
  computed: {
    header() {
      const route = this.$route.matched?.find((route) => route.meta?.header);
      return route?.meta?.header;
    },
  },

  methods: {
    handleNavigation() {
      if (this.header.route) {
        this.$router.replace(this.header.route);
      }
    },
  },
});
