import { RouteConfig } from 'vue-router';
import Form from '@/views/layout/Form.vue';
import i18n from '@/i18n';

const routes: Array<RouteConfig> = [
  {
    path: 'languageDesignation',
    name: 'languageDesignation',
    component: Form,
    redirect: { name: 'languageDesignation-list' },
    meta: {
      session: true,
    },
    children: [
      {
        path: 'list',
        name: 'languageDesignation-list',
        component: () =>
          import('@/views/languageDesignation/LanguageDesignationList.vue'),
        meta: {
          header: {
            label: () => i18n.tc('languageDesignation.label', 2),
          },
        },
      },
      {
        path: 'create',
        name: 'languageDesignation-create',
        component: () =>
          import('@/views/languageDesignation/LanguageDesignationCreate.vue'),
        meta: {
          header: {
            route: { name: 'languageDesignation-list' },
            label: () =>
              i18n.t('title.create', [i18n.tc('languageDesignation.label', 2)]),
          },
        },
      },
      {
        path: 'edit/:id',
        name: 'languageDesignation-edit',
        component: () =>
          import('@/views/languageDesignation/LanguageDesignationEdit.vue'),
        meta: {
          header: {
            route: { name: 'languageDesignation-list' },
            label: () =>
              i18n.t('title.edit', [i18n.tc('languageDesignation.label', 2)]),
          },
        },
      },
    ],
  },
];

export default routes;
