import { RouteConfig } from 'vue-router';
import Form from '@/views/layout/Form.vue';
import i18n from '@/i18n';

const routes: Array<RouteConfig> = [
  {
    path: 'email',
    name: 'email',
    component: Form,
    redirect: { name: 'email-list' },
    meta: {
      session: true,
    },
    children: [
      {
        path: 'list',
        name: 'email-list',
        component: () => import('@/views/email/EmailList.vue'),
        meta: {
          header: {
            label: () => i18n.tc('email.label', 2),
          },
        },
      },
      {
        path: 'create',
        name: 'email-create',
        component: () => import('@/views/email/EmailCreate.vue'),
        meta: {
          header: {
            route: { name: 'email-list' },
            label: () => i18n.t('title.create', [i18n.tc('email.label', 2)]),
          },
        },
      },
      {
        path: 'edit/:key',
        name: 'email-edit',
        component: () => import('@/views/email/EmailEdit.vue'),
        meta: {
          header: {
            route: { name: 'email-list' },
            label: () => i18n.t('title.edit', [i18n.tc('email.label', 2)]),
          },
        },
      },
    ],
  },
];

export default routes;
