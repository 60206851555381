import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import axios from 'axios';
import i18n, { locales } from '@/i18n';
import vuetify from '@/plugins/vuetify';
import AuthUtils, { SessionVM } from '@/utils/AuthUtils';
import '@/components/framework'; // import global registered custom components
import LocaleUtils from './utils/LocaleUtils';
import ValidateUtils from './utils/ValidateUtils';
import toast from './components/framework/toast';
import { Storage, StorageVM } from './utils/StorageUtils';
import moment from 'moment';
moment.locale('fr-CA');

Vue.config.productionTip = false;

axios.defaults.baseURL =
  process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PREFIX;

axios.interceptors.response.use(
  async (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      AuthUtils.api.logout();
      router.push({ name: 'login' });
    }
    return Promise.reject(error);
  }
);

Vue.mixin(
  Vue.extend({
    computed: {
      $session() {
        return SessionVM.$data.session;
      },

      $moment() {
        return moment;
      },

      $storage(): Storage {
        return StorageVM.$data as Storage;
      },

      $locales(): string[] {
        return locales.map((locale) => locale.value);
      },

      $toast() {
        return toast;
      },

      $validators() {
        return ValidateUtils.validators;
      },

      $langobj() {
        return LocaleUtils.langobj;
      },

      $ipaddress() {
        const tags = document.getElementsByTagName('meta');
        for (const i in tags) {
          if (
            typeof tags[i] === 'object' &&
            tags[i].getAttribute('name') === 'ipaddress'
          ) {
            return tags[i].getAttribute('content') || undefined;
          }
        }
        return undefined;
      },
    },
  })
);

// Privileges - create v-can directive
Vue.directive('priv', {
  bind: function (el, binding /*, vnode*/) {
    if (
      binding.expression == "'admin'" &&
      !(
        SessionVM.$data.session.user.privilege == 'admin' ||
        SessionVM.$data.session.user.privilege == 'superadmin'
      )
    ) {
      el.style.display = 'none';
    }
    if (
      binding.expression == "'superadmin'" &&
      !(SessionVM.$data.session.user.privilege == 'superadmin')
    ) {
      el.style.display = 'none';
    }
  },
});

new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
