import Vue from 'vue';
import axios from 'axios';
import router from '@/router';
import { User } from './UserUtils';

interface Session {
  token: string;
  user: User;
}

const api = {
  login: async (username: string, password: string) => {
    const resp = await axios.post<Session>('authentication/login.php', {
      username,
      password,
    });
    SessionVM.$data.session = resp.data;
    return SessionVM.$data.session;
  },

  logout: async () => {
    SessionVM.$data.session = undefined;
  },

  validateToken: async (token: string) => {
    await axios.get('authentication/validate_token.php', {
      params: { token: token },
    });
  },

  passwordReset: async (accountsearch: string) => {
    await axios.post<void>('authentication/password_reset.php', {
      accountsearch,
    });
  },

  passwordChange: async (token: string, password: string) => {
    await axios.post<void>('authentication/password_change.php', {
      token: token,
      password: password,
    });
  },
};

const SessionVM = new Vue({
  data(): {
    session?: Session;
  } {
    const local = localStorage.getItem('session');
    return {
      session: local ? JSON.parse(local) : undefined,
    };
  },

  watch: {
    session: {
      immediate: true,
      handler() {
        if (this.session) {
          localStorage.setItem('session', JSON.stringify(this.session));
          axios.defaults.headers.common['X-Custom-Authorization'] =
            this.session.token;
        } else {
          localStorage.removeItem('session');
          delete axios.defaults.headers.common['X-Custom-Authorization'];

          if (
            router &&
            router.currentRoute?.matched?.some((route) => route.meta.session)
          ) {
            router.replace({ name: 'home' });
          }
        }
      },
    },
  },
});

export default { api };
export { Session, SessionVM };
