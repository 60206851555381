


































































import Vue from 'vue';
// import axios from 'axios';
import LocaleSelector from '@/components/locale/LocaleSelector.vue';
import AuthUtils from '@/utils/AuthUtils';

export default Vue.extend({
  name: 'Login',
  data: (): {
    username: string;
    password: string;
    respData: unknown;
    errorCode?: string;
    error: string;
    showPassword: boolean;
  } => ({
    username: '',
    password: '',
    respData: {},
    errorCode: '',
    error: '',
    showPassword: false,
  }),
  components: {
    LocaleSelector,
  },
  computed: {
    title() {
      return this.$langobj.parse(JSON.parse(process.env.VUE_APP_TITLE));
    },
  },
  methods: {
    async handleLogin() {
      try {
        this.errorCode = undefined;
        await AuthUtils.api.login(this.username, this.password);
        this.$router.push({ name: 'home' });
      } catch (error: any) {
        this.errorCode = error.response.data.message;
      } finally {
        //
      }

      return false;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
});
