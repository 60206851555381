















































import NavigationBar from '@/components/framework/navigation/NavigationBar.vue';
import Vue from 'vue';

export default Vue.extend({
  components: { NavigationBar },

  computed: {
    items() {
      return [
        {
          header: this.$tc('fls_activity.label', 2),
        },
        {
          title: this.$tc('fls_activity.log'),
          icon: 'mdi-format-list-checks',
          route: { name: 'fls-activity' },
        },
        {
          header: this.$tc('common.data'),
        },
        {
          title: this.$tc('provider.label'),
          icon: 'mdi-file-tree',
          route: { name: 'provider' },
        },
        // {
        //   title: this.$tc('provider.category.label'),
        //   icon: 'mdi-shape',
        //   route: { name: 'provider-category' },
        // },
        // {
        //   title: this.$tc('healthteam.label'),
        //   icon: 'mdi-group',
        //   route: { name: 'healthteam' },
        // },
        // {
        //   title: this.$tc('province.label'),
        //   icon: 'mdi-sign-real-estate',
        //   route: { name: 'province' },
        // },
        {
          title: this.$tc('community.label'),
          icon: 'mdi-home-group',
          route: { name: 'community' },
        },
        {
          title: this.$tc('pointofservice.label'),
          icon: 'mdi-map-marker ',
          route: { name: 'pointofservice' },
        },
        {
          title: this.$tc('sectorofcare.label'),
          icon: 'mdi-format-list-bulleted-type',
          route: { name: 'sectorofcare' },
        },
        {
          title: this.$tc('healthProfessional.label'),
          icon: 'mdi-account-group',
          route: { name: 'health-professional' },
        },
        // {
        //   title: this.$tc('healthProfessionField.label'),
        //   icon: 'mdi-professional-hexagon',
        //   route: { name: 'health-profession-field' },
        // },
        // {
        //   title: this.$tc('tag.domain.specialty'),
        //   icon: 'mdi-shield-star',
        //   route: { name: 'tag-specialty' },
        // },
        {
          title: this.$tc('languageCapability.label'),
          icon: 'mdi-web-box',
          route: { name: 'languageCapability' },
        },
        {
          title: this.$tc('languageDesignation.label'),
          icon: 'mdi-file-certificate',
          route: { name: 'languageDesignation' },
        },
        {
          title: this.$tc('enumeration.label', 2),
          icon: 'mdi-format-list-numbered',
          route: { name: 'enumeration' },
        },
      ];
    },
    privItems() {
      return [
        {
          header: this.$tc('common.system'),
        },
        {
          title: this.$tc('user.label'),
          icon: 'mdi-account',
          route: { name: 'user' },
        },
        {
          title: this.$tc('user-agreement.label', 2),
          icon: 'mdi-handshake',
          route: { name: 'user-agreement' },
        },
        {
          title: this.$tc('email.label'),
          icon: 'mdi-email',
          route: { name: 'email' },
        },
        {
          title: this.$tc('dataset.label'),
          icon: 'mdi-briefcase-search-outline',
          route: { name: 'dataset' },
        },
        {
          title: this.$tc('navigation.version'),
          icon: 'mdi-git',
          route: { name: 'version' },
        },
      ];
    },
  },
});
