import { RouteConfig } from 'vue-router';
import Form from '@/views/layout/Form.vue';
import i18n from '@/i18n';

const routes: Array<RouteConfig> = [
  {
    path: 'tag/specialty',
    name: 'tag-specialty',
    component: Form,
    redirect: { name: 'tag-specialty-list' },
    meta: {
      session: true,
    },
    children: [
      {
        path: 'list',
        name: 'tag-specialty-list',
        component: () => import('@/views/tag/specialty/TagSpecialtyList.vue'),
        meta: {
          header: {
            label: () =>
              i18n.t('title.list', [i18n.tc('tag.domain.specialty', 2)]),
          },
        },
      },
      {
        path: 'create',
        name: 'tag-specialty-create',
        component: () => import('@/views/tag/specialty/TagSpecialtyCreate.vue'),
        meta: {
          header: {
            route: { name: 'tag-specialty-list' },
            label: () =>
              i18n.t('title.create', [i18n.tc('tag.domain.specialty', 2)]),
          },
        },
      },
      {
        path: 'edit/:id',
        name: 'tag-specialty-edit',
        component: () => import('@/views/tag/specialty/TagSpecialtyEdit.vue'),
        meta: {
          header: {
            route: { name: 'tag-specialty-list' },
            label: () =>
              i18n.t('title.edit', [i18n.tc('tag.domain.specialty', 2)]),
          },
        },
      },
    ],
  },
];

export default routes;
