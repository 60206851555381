import { setI18n } from '@/i18n';
import { SessionVM } from '@/utils/AuthUtils';
import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  await setI18n();
  if (
    to.matched?.some((route) => !route.meta.public) &&
    !SessionVM.$data.session
  ) {
    next({ name: 'login' }); // redirect home
  } else {
    next();
  }
});

export default router;
