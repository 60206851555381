import { RouteConfig } from 'vue-router';
import Form from '@/views/layout/Form.vue';
import Tabs from '@/views/layout/Tabs.vue';
import i18n from '@/i18n';

const routes: Array<RouteConfig> = [
  {
    path: 'provider',
    name: 'provider',
    component: Form,
    redirect: { name: 'provider-list' },
    meta: {
      session: true,
    },
    children: [
      {
        path: 'list',
        name: 'provider-list',
        component: () => import('@/views/provider/ProviderList.vue'),
        meta: {
          header: {
            label: () => i18n.tc('provider.label', 2),
          },
        },
      },
      {
        path: 'create',
        name: 'provider-create',
        component: () => import('@/views/provider/ProviderCreate.vue'),
        meta: {
          header: {
            route: { name: 'provider-list' },
            label: () => i18n.t('title.create', [i18n.tc('provider.label', 2)]),
          },
        },
      },
      {
        path: 'edit/:id(\\d+)',
        name: 'provider-edit',
        component: Tabs,
        meta: {
          header: {
            route: { name: 'provider-list' },
            label: () => i18n.t('title.edit', [i18n.tc('provider.label', 2)]),
          },
          tabs: [
            {
              route: { name: 'provider-edit-form' },
              display: () => i18n.t('navigation.info'),
            },
            {
              route: { name: 'provider-edit-description' },
              display: () => i18n.t('provider.description'),
            },
            {
              route: { name: 'provider-edit-service' },
              display: () => i18n.tc('service.label', 2),
            },
            {
              route: { name: 'provider-edit-pointofservice' },
              display: () => i18n.tc('pointofservice.label', 2),
            },
          ],
        },
        redirect: { name: 'provider-edit-form' },
        children: [
          {
            path: '',
            name: 'provider-edit-form',
            component: () => import('@/views/provider/ProviderEdit.vue'),
          },
          {
            path: 'description',
            name: 'provider-edit-description',
            component: () =>
              import('@/views/provider/ProviderEditDescriptionService.vue'),
          },
          {
            path: 'service',
            name: 'provider-edit-service',
            component: () => import('@/views/provider/ProviderEditService.vue'),
          },
          {
            path: 'pointofservice',
            name: 'provider-edit-pointofservice',
            component: () => import('@/views/provider/ProviderEditPOS.vue'),
          },
        ],
      },
    ],
  },
  {
    path: 'provider_category',
    name: 'provider-category',
    component: Form,
    redirect: { name: 'provider-category-list' },
    meta: {
      session: true,
    },
    children: [
      {
        path: 'list',
        name: 'provider-category-list',
        component: () =>
          import('@/views/provider/category/ProviderCategoryList.vue'),
        meta: {
          header: {
            label: () =>
              i18n.t('title.list', [i18n.tc('provider.category.label', 2)]),
          },
        },
      },
      {
        path: 'create',
        name: 'provider-category-create',
        component: () =>
          import('@/views/provider/category/ProviderCategoryCreate.vue'),
        meta: {
          header: {
            route: { name: 'provider-category-list' },
            label: () =>
              i18n.t('title.create', [i18n.tc('provider.category.label', 2)]),
          },
        },
      },
      {
        path: 'edit/:key',
        name: 'provider-category-edit',
        component: () =>
          import('@/views/provider/category/ProviderCategoryEdit.vue'),
        meta: {
          header: {
            route: { name: 'provider-category-list' },
            label: () =>
              i18n.t('title.edit', [i18n.tc('provider.category.label', 2)]),
          },
        },
      },
    ],
  },
];

export default routes;
