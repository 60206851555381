























import Vue from 'vue';
import { SnackBarProp } from '.';

export default Vue.extend({
  props: {
    value: {
      type: Object as () => SnackBarProp,
    },
  },

  data(): {
    visible: boolean;
  } {
    return {
      visible: true,
    };
  },

  watch: {
    visible(visible: boolean) {
      if (!visible) {
        setTimeout(() => {
          (this.$options as any).close();
        }, 500);
      }
    },
  },
});
