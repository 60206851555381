import Vue from 'vue';

interface Storage {
  [key: string]: any;
}

interface TableStorage {
  search?: string;
  sortBy?: string;
  sortDesc?: boolean;
}

const StorageVM = new Vue({
  data(): Storage {
    return {
      communityListTable: {
        search: undefined,
        sortBy: undefined,
        sortDesc: undefined,
      },
      enumerationListTable: {
        search: undefined,
        sortBy: undefined,
        sortDesc: undefined,
      },
      flsActivityListTable: {
        search: undefined,
        sortBy: undefined,
        sortDesc: undefined,
        createdBy: undefined,
      },
      providerListTable: {
        search: undefined,
        sortBy: undefined,
        sortDesc: undefined,
      },
      providerCategoryListTable: {
        search: undefined,
        sortBy: undefined,
        sortDesc: undefined,
      },
      userListTable: {
        search: undefined,
        sortBy: undefined,
        sortDesc: undefined,
      },
      emailListTable: {
        search: undefined,
        sortBy: undefined,
        sortDesc: undefined,
      },
      provinceListTable: {
        search: undefined,
        sortBy: undefined,
        sortDesc: undefined,
      },
      pointOfServiceListTable: {
        search: undefined,
        sortBy: undefined,
        sortDesc: undefined,
      },
      healthProfessionalListTable: {
        search: undefined,
        sortBy: undefined,
        sortDesc: undefined,
      },
      healthProfessionFieldListTable: {
        search: undefined,
        sortBy: undefined,
        sortDesc: undefined,
      },
      healthTeamListTable: {
        search: undefined,
        sortBy: undefined,
        sortDesc: undefined,
      },
      languageCapabilityListTable: {
        search: undefined,
        sortBy: undefined,
        sortDesc: undefined,
      },
      languageDesignationListTable: {
        search: undefined,
        sortBy: undefined,
        sortDesc: undefined,
      },
      tagSpecialtyListTable: {
        search: undefined,
        sortBy: undefined,
        sortDesc: undefined,
      },
      sectorOfCareListTable: {
        search: undefined,
        sortBy: undefined,
        sortDesc: undefined,
      },
      serviceListTable: {
        search: undefined,
        sortBy: undefined,
        sortDesc: undefined,
        customFilters: {
          showFunctionalCentres: false,
        },
      },
    };
  },
});

export { Storage, StorageVM, TableStorage };
