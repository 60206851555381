import { RouteConfig } from 'vue-router';
import Admin from '@/views/layout/Admin.vue';
import Standard from '@/views/layout/Standard.vue';
import Fullscreen from '@/views/layout/Fullscreen.vue';
import Login from '@/views/authentication/Login.vue';
import ForgotPassword from '@/views/authentication/ForgotPassword.vue';

// TODO based on deployment, override routes somehow
import provider from './deployment/common/provider';
import user from './deployment/common/user';
import userAgreement from './deployment/common/userAgreement';
import email from './deployment/common/email';
import province from './deployment/common/province';
import pointOfService from './deployment/common/pointOfService';
import community from './deployment/common/community';
import enumeration from './deployment/common/enumeration';
import sectorOfCare from './deployment/common/sectorOfCare';
import healthProfessional from './deployment/common/healthProfessional';
import healthProfessionField from './deployment/common/healthProfessionField';
import languageCapability from './deployment/common/languageCapability';
import languageDesignation from './deployment/common/languageDesignation';
import healthTeam from './deployment/common/healthTeam';
import tag from './deployment/common/tag';
import dataset from './deployment/common/dataset';
import service from './deployment/common/service';
import flsActivity from './deployment/common/flsActivity';

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Standard,
    name: 'home',
    redirect: { name: 'search' },
    children: [
      {
        path: '',
        name: 'search',
        component: () => import('@/views/Search.vue'),
      },
    ],
  },
  {
    path: '/',
    meta: { public: true }, // Does not require a session
    component: Fullscreen,
    children: [
      {
        path: 'password',
        meta: { public: true }, // Does not require a session
        name: 'password-change',
        component: () => import('@/views/authentication/PasswordChange.vue'),
      },
    ],
  },
  {
    path: '/login',
    meta: { public: true }, // Does not require a session
    name: 'login',
    component: Login,
  },
  {
    path: '/forgotpassword',
    meta: { public: true }, // Does not require a session
    name: 'forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/admin',
    component: Admin,
    name: 'admin',
    children: [
      {
        path: 'version',
        name: 'version',
        component: () => import('@/views/Version.vue'),
      },
      ...provider,
      ...user,
      ...userAgreement,
      ...email,
      ...province,
      ...service,
      ...pointOfService,
      ...sectorOfCare,
      ...healthProfessional,
      ...healthProfessionField,
      ...languageCapability,
      ...languageDesignation,
      ...healthTeam,
      ...tag,
      ...dataset,
      ...community,
      ...enumeration,
      ...flsActivity,
    ],
  },
];

export default routes;
