









import Vue from 'vue';
import NavigationBar from '@/components/framework/navigation/NavigationBar.vue';

export default Vue.extend({
  components: { NavigationBar },
});
