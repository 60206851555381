















































































import Vue from 'vue';
import AuthUtils from '@/utils/AuthUtils';
import PasswordReset from './PasswordReset.vue';

export default Vue.extend({
  components: { PasswordReset },
  data: (): {
    visible: boolean;
    valid: boolean;
    errorCode?: string;
    username: string;
    password: string;
  } => ({
    visible: false,
    valid: false,
    errorCode: undefined,
    username: '',
    password: '',
  }),

  methods: {
    async handleLogin() {
      try {
        this.errorCode = undefined;
        await AuthUtils.api.login(this.username, this.password);
        this.visible = false;
      } catch (error: any) {
        this.errorCode = error.response.data.message;
      } finally {
        //
      }

      return false;
    },

    async handleLogout() {
      try {
        await AuthUtils.api.logout();
        this.visible = false;
        this.$router.push({ name: 'login' });
      } catch (error) {
        console.error(error);
      } finally {
        //
      }
    },

    async handleAdmin() {
      await this.$router.push({ name: 'admin' });
    },
  },

  watch: {
    visible() {
      if (this.visible) {
        this.valid = false;
        this.username = '';
        this.password = '';
        this.errorCode = undefined;

        if (this.$refs.form) {
          (this.$refs.form as any).resetValidation();
        }
      }
    },
  },
});
