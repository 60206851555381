import i18n from '@/i18n';

const validators = {
  required: (val: any, messages: string[]) => {
    return !!val || i18n.t('error.required', messages);
  },

  uri: (val: any, messages: string[]) => {
    // TODO: import and use validator from npm
    return true || i18n.t('error.invalid', messages);
  },

  phone_number: (val: string, messages: string[]) => {
    const phoneRegExp =
      /(?=\(|\b)(?:\+?1 ?[-.]?)?(?:\(\d{3}\)|\d{3}) ?[-.]? ?\d{3} ?[-.]? ?\d{4}\b/g;
    return (
      (val ? phoneRegExp.test(val) : true) || i18n.t('error.invalid', messages)
    );
  },
  email: (val: string, messages: string[]) => {
    const emailRegExp =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g;
    return (
      (val ? emailRegExp.test(val) : true) || i18n.t('error.invalid', messages)
    );
  },
  number: (val: string, messages: string[]) => {
    const regExp = /^[0-9]*$/g;
    return (
      (val ? regExp.test(val) : true) || i18n.t('validator.number', messages)
    );
  },
  password: (val: string, messages: string[]) => {
    const passwordRegExp =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

    return (
      (val ? passwordRegExp.test(val) : true) ||
      i18n.t('password.invalid_password_format', messages)
    );
  },
};

export default { validators };
