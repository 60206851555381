import unorm from 'unorm';
import LocaleUtils from './LocaleUtils';

const clean = (text: string) => {
  return unorm
    .nfd(text)
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};

const sort = {
  locale: (a: any, b: any): -1 | 0 | 1 => {
    const aName = clean(LocaleUtils.langobj.parse(a) || '');
    const bName = clean(LocaleUtils.langobj.parse(b) || '');
    return aName == bName ? 0 : aName > bName ? 1 : -1;
  },
};

export default {
  sort,
  clean,
};
