













import Vue from 'vue';

export default Vue.extend({
  computed: {
    tabs() {
      const route = this.$router.currentRoute.matched.find(
        (route) => !!route.meta.tabs
      );
      return route?.meta.tabs;
    },
  },

  data: () => ({
    //
  }),

  mounted() {
    //
  },
});
