




































import AuthUtils from '@/utils/AuthUtils';
import Vue from 'vue';

export default Vue.extend({
  data: (): {
    state: {
      visible: boolean;
      valid: boolean;
      submit: boolean;
    };
    username: string;
  } => ({
    state: {
      visible: false,
      valid: false,
      submit: false,
    },
    username: '',
  }),

  methods: {
    async handleSubmit() {
      try {
        this.state.submit = true;
        (this.$refs.form as any).validate();
        await AuthUtils.api.passwordReset(this.username);
        (this.$refs.form as any).resetValidation();
        this.state.visible = false;
        this.$toast.push({
          text: this.$t('auth.password.reset.success'),
          type: 'success',
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.state.submit = false;
      }
    },

    handleCancel() {
      this.state.visible = false;
      this.username = '';
      (this.$refs.form as any).resetValidation();
    },
  },
});
