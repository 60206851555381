




























import UserAgreementUtils, { UserAgreement } from '@/utils/UserAgreementUtils';
import LocaleSelector from '@/components/locale/LocaleSelector.vue';
import Vue from 'vue';

export default Vue.extend({
  components: {
    LocaleSelector,
  },

  props: {},

  computed: {
    visible(): boolean {
      return !!this.currentAgreement;
    },
  },

  data: (): {
    checkbox: boolean;
    currentAgreement?: UserAgreement;
    activeList: UserAgreement[];
  } => ({
    checkbox: false,
    currentAgreement: undefined,
    activeList: [],
  }),

  mounted() {
    this.getActiveUserAgreements();
  },

  methods: {
    async getActiveUserAgreements() {
      try {
        this.activeList =
          await UserAgreementUtils.userAgreement.api.listActiveTerm();
        if (this.activeList.length > 0) {
          this.getAndDisplayNextAgreement();
        }
      } catch (error) {
        console.error(error);
      }
    },

    async submit() {
      try {
        if (this.currentAgreement && this.checkbox) {
          UserAgreementUtils.userAgreement.api.setAcknowledged(
            this.currentAgreement?.key
          );
        }
        this.getAndDisplayNextAgreement();
      } catch (error) {
        console.error(error);
      }
    },

    async getAndDisplayNextAgreement() {
      try {
        this.currentAgreement = undefined;
        let nextAgreement;
        while ((nextAgreement = this.activeList.shift()) != undefined) {
          if (
            UserAgreementUtils.userAgreement.api.isAcknowledged(
              nextAgreement.key
            )
          ) {
            continue;
          }
          this.currentAgreement =
            await UserAgreementUtils.userAgreement.api.get(nextAgreement.id);
          break;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
