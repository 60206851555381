import { RouteConfig } from 'vue-router';
import Form from '@/views/layout/Form.vue';

const routes: Array<RouteConfig> = [
  {
    path: 'dataset',
    name: 'dataset',
    component: Form,
    redirect: { name: 'dataset-generate' },
    meta: {
      session: true,
    },
    children: [
      {
        path: '',
        name: 'dataset-generate',
        component: () => import('@/views/dataset/DataSet.vue'),
      },
    ],
  },
];

export default routes;
