














































































import Vue from 'vue';
import LocaleSelector from '@/components/locale/LocaleSelector.vue';
import AuthUtils from '@/utils/AuthUtils';

export default Vue.extend({
  name: 'ForgotPassword',
  components: {
    LocaleSelector,
  },
  data: (): {
    identifier: string;
    showPassword: boolean;
    respData: any;
    tokenError: boolean;
    success: boolean;
    error: boolean;
    loading: boolean;
  } => ({
    identifier: '',
    showPassword: false,
    respData: {},
    tokenError: false,
    success: false,
    error: false,
    loading: false,
  }),
  computed: {
    enableSubmit() {
      return this.identifier.length > 2;
    },
    title() {
      return this.$langobj.parse(JSON.parse(process.env.VUE_APP_TITLE));
    },
  },
  methods: {
    async handleSubmit() {
      try {
        this.loading = true;
        await AuthUtils.api.passwordReset(this.identifier);
        this.error = false;
        this.success = true;
        this.loading = false;
      } catch (error: any) {
        this.error = true;
        this.success = false;
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
});
