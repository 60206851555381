
























import i18n, { locales, setI18n } from '@/i18n';
import Vue from 'vue';

export default Vue.extend({
  computed: {
    locales(): typeof locales {
      return locales;
    },

    selected: {
      get(): number {
        return this.locales.findIndex((locale) => locale.value === i18n.locale);
      },
      set(selected: number) {
        setI18n(this.locales[selected]?.value);
      },
    },
  },
});
