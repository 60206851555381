

















































import Vue from 'vue';
import SessionLauncher from '@/components/session/SessionLauncher.vue';
import LocaleSelector from '@/components/locale/LocaleSelector.vue';
import UserAgreementInfoModal from '@/components/userAgreement/UserAgreementInfoModal.vue';

export default Vue.extend({
  components: {
    'session-launcher': SessionLauncher,
    'locale-selector': LocaleSelector,
    'user-agreement-info-modal': UserAgreementInfoModal,
  },

  data(): {
    betaVisible: boolean;
  } {
    return {
      betaVisible: false,
    };
  },

  computed: {
    logo() {
      return process.env.VUE_APP_API_URL + process.env.VUE_APP_LOGO;
    },

    logoRoute() {
      if (this.$session && this.$route.path == '/') {
        return { name: 'admin' };
      }
      return { name: 'home' };
    },

    title() {
      return this.$langobj.parse(JSON.parse(process.env.VUE_APP_TITLE));
    },

    isBeta() {
      return process.env.VUE_APP_BETA === 'true';
    },

    menuItems() {
      return [
        {
          title: this.$tc('navigation.search'),
          icon: 'mdi-map-search',
          to: { name: 'home' },
        },
        {
          title: this.$tc('navigation.data'),
          icon: 'mdi-database-marker',
          to: { name: 'admin' },
        },
      ];
    },
  },
});
