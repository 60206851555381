import Vue from 'vue';

Vue.component('ol-feature', () => import('./map/OlFeature.vue'));
Vue.component('ol-layer', () => import('./map/OlLayer.vue'));
Vue.component('ol-map', () => import('./map/OlMap.vue'));
Vue.component('ol-overlay', () => import('./map/OlOverlay.vue'));
Vue.component('ozi-spinner', () => import('./Spinner.vue'));
Vue.component('ozi-table', () => import('./table/Table.vue'));
Vue.component('tip-tap', () => import('./tiptap/TipTap.vue'));
Vue.component('ozi-verification', () => import('./Verification.vue'));
